import classes from "./LanguageToggle.module.css";

function LanguageToggle(props) {
  return (
    <div className={classes.languagetoggle} onClick={props.toggleLanguage}>
      <div
        className={`${classes.languageoption} ${classes.englishlanguage} ${
          props.selectedLanguage === "en"
            ? `${classes.active}`
            : `${classes.inactive}`
        }`}
      >
        <div className={classes.text}>EN</div>
      </div>
      <div
        className={`${classes.languageoption} ${classes.polishlanguage} ${
          props.selectedLanguage === "pl"
            ? `${classes.active}`
            : `${classes.inactive}`
        }`}
      >
        <div className={classes.text}>PL</div>
      </div>
    </div>
  );
}

export default LanguageToggle;
