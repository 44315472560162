import classes from "./LogoBar.module.css";
import { Link } from "react-router-dom";
import { AppHelper } from "../App";

function LogoBar() {
  const backgroundColor = AppHelper.backgroundColor || "";
  const logoBarStyle = {
    backgroundColor: backgroundColor, // Set the backgroundColor using the value from AppHelper
  };
  return (
    <div className={classes.box} style={logoBarStyle}>
      <div className={classes.titleheader}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className={classes.title}>scyzoryk.xyz</div>
        </Link>
      </div>
    </div>
  );
}

export default LogoBar;
