import "./App.css";
import React from "react";

import { useState, useEffect } from "react";
import { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CurriculumPage from "./components/CurriculumPage";
import EntriesPage from "./components/EntriesPage";
import LinksPage from "./components/LinksPage";
import HeroPage from "./components/HeroPage";
import NavBar from "./components/NavBar";
import LogoBar from "./components/LogoBar";
import LanguageToggle from "./components/LanguageToggle";

import { Link } from "react-router-dom";

import EntryPost from "./components/EntryPost";

import AddressBar from "./components/AddressBar";

export const LanguageContext = React.createContext();

export class AppHelper {
  static developerMode = true;
  static backgroundColor = "#ffffff"; // Define a static property for backgroundColor
}

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState(`en`);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const toggleLanguage = () => {
    setSelectedLanguage((prevLanguage) =>
      prevLanguage === "en" ? "pl" : "en"
    );
  };

  const generateRandomColor = () => {
    const hue = Math.floor(Math.random() * 360); // Random hue angle between 0 and 359
    const randomColor = `hsla(${hue}, 0%, 52%, 1)`;
    setBackgroundColor(randomColor);
    AppHelper.backgroundColor = randomColor;
  };

  useEffect(() => {
    generateRandomColor();
  }, []);

  return (
    <Fragment>
      <LanguageContext.Provider value={selectedLanguage}>
        <Router>
          <div
            className="App"
            style={{ backgroundColor: backgroundColor }}
          ></div>
          <LogoBar></LogoBar>
          <NavBar></NavBar>
          {/* <AddressBar></AddressBar> */}
          <LanguageToggle
            selectedLanguage={selectedLanguage}
            toggleLanguage={toggleLanguage}
          ></LanguageToggle>

          {/* <ReactMarkdown>{markdown}</ReactMarkdown> */}
          {/* <p>Contact me @ hello@scyzoryk.xyz</p> */}

          <Routes>
            <Route path="/" element={<HeroPage />} />
            <Route path="/cv" element={<CurriculumPage />} />
            {/* <Route path="/entries" element={<EntriesPage />} /> */}
            {/* <Route exact path="/entries/:id" element={<EntryPost />} /> */}
            {/* <Route path="/links" element={<LinksPage />} /> */}
          </Routes>
        </Router>
      </LanguageContext.Provider>
    </Fragment>
  );
}

export default App;
