import React from "react";
import { useState, useEffect } from "react";

import { useContext } from "react";
import { LanguageContext } from "../App";
import Markdown from "markdown-to-jsx";
import classes from "./HeroPage.module.css";

function HeroPage() {
  const [mdcontent, setMdcontent] = useState("");

  const lang = useContext(LanguageContext);

  useEffect(() => {
    const file_name = `firstpost_${lang}.md`;

    import(`../content/${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setMdcontent(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [lang]);

  return (
    <div className={classes.page}>
      <Markdown>{mdcontent}</Markdown>
    </div>
  );
}

export default HeroPage;
