import React from "react";
import classes from "./AddressBar.module.css";
import { useLocation } from "react-router-dom";

function AddressBar() {
  const location = useLocation();
  console.log(location.pathname);
  return <div className={classes.bar}></div>;
}

export default AddressBar;
