import React, { Fragment, useRef } from "react";
import classes from "./NavBar.module.css";
import { Link } from "react-router-dom";
import { AppHelper } from "../App";

function NavBar() {
  const backgroundColor = AppHelper.backgroundColor || "";
  const logoBarStyle = {
    backgroundColor: backgroundColor, // Set the backgroundColor using the value from AppHelper
  };

  const RectSVG = () => (
    <div className={classes.burgerbox} style={logoBarStyle}>
      <svg
        className={classes.burger}
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0" width="22" height="3" fill="#EBEBEB" />
        <rect y="6" width="22" height="3" fill="#EBEBEB" />
        <rect y="12" width="22" height="3" fill="#EBEBEB" />
      </svg>
    </div>
  );

  const navToggleRef = useRef(null);

  const toggleDrawer = () => {
    if (navToggleRef.current) {
      navToggleRef.current.checked = !navToggleRef.current.checked;
    }
  };

  return (
    <Fragment>
      <input
        ref={navToggleRef}
        type="checkbox"
        id="navToggle"
        className={classes.navToggle}
      />
      <label htmlFor="navToggle" className={classes.navToggleLabel}>
        <RectSVG />
      </label>
      <div className={classes.drawerbox}>
        <header>
          <nav className={classes.nav}>
            <ul className={classes.ul}>
              {/* <li className={classes.link}>
                <Link to="/" onClick={toggleDrawer}>
                  <div>Home</div>
                </Link>
              </li> */}
              <li className={classes.link}>
                <Link to="/cv" onClick={toggleDrawer}>
                  <div>CV</div>
                </Link>
              </li>
              {/* <li className={classes.link}>
                <Link to="/entries" onClick={toggleDrawer}>
                  <div >Entries</div>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/links" onClick={toggleDrawer}>Links</Link>
              </li> */}
            </ul>
          </nav>
        </header>
      </div>
    </Fragment>
  );
}

export default NavBar;
